import React from 'react';
import { Container } from 'reactstrap';

function Error({ status, message }: {status?: number, message?: string}) {
  let title: JSX.Element | string = 'Er ging iets mis';

  if (status) {
    switch (status) {
      case 404:
        title = <h2 className="text-center">De pagina die je zocht werd niet gevonden</h2>;
        break;
      case 401:
        title = (
          <>
            <h2 className="text-center">
              Je hebt niet de juiste machtigingen om deze pagina te bekijken.
            </h2>
            <p className="text-center">Ben je ingelogd met de juiste account?</p>
            {/* <div className="d-flex justify-content-center">
              <Link href="/login">
                <a className="btn btn-primary">Naar de login-pagina</a>
              </Link>
            </div> */}
          </>
        );
        break;
      case 500:
        title = <h2>Er ging iets mis</h2>;
        break;
      default:
        break;
    }
  }

  return (
    <Container className="mt-5">
      <div className="p-5">
        {title && title}
        {message && <p className="mt-4 text-center">{message}</p>}
      </div>
    </Container>
  );
}

export default Error;
